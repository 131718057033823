import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import MyPage from '../views/MyPage.vue';
import TeamPage from '../views/TeamPage.vue';
import WithdrawalsPage from '../views/WithdrawalsPage.vue';
import DepositPage from '../views/DepositPage.vue';
import OrderPage from '../views/OrderPage.vue';
import OrderDetail from '../views/OrderDetail.vue';
import productDetail from '../views/productDetail.vue';
import HistoryPage from '../views/HistoryPage.vue';



const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/my',
        name: 'MyPage',
        component: MyPage
    },{
        path: '/team',
        name: 'TeamPage',
        component: TeamPage

    },{
        path: '/withdrawals',
        name: 'WithdrawalsPage',
        component: WithdrawalsPage
    }
    ,{
        path: '/deposit',
        name: 'DepositPage',
        component: DepositPage
    },{
        path: '/order',
        name: 'OrderPage',
        component: OrderPage
    },{
        path: '/orderdetail/:id/:product_name/:create_at/:end_at/:arp/:et_arp/:status/:amount',
        name: 'OrderDetail',
        component: OrderDetail
    },{
        path: '/productdetail/:id/:name/:arp/:start_funds/:cycle/:describe',
        name: 'productDetail',
        component: productDetail
    },{
        path: '/history',
        name: 'HistoryPage',
        component: HistoryPage

    },



];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
