<template>
  <main>
    <div  style="text-align: center" v-if="data.length == 0"> <span>暂无任何账单</span></div>
    <div class="account-list" v-if="data.length>0">
      <div  v-for="(item, index) in data"  :key = "index"  class="account-item">
        <div class="account-id">{{item["create_at"]}}</div>
        <div class="account-id">{{item["amount"].toFixed(2)}}</div>
        <div class="account-id">{{type[item["type"]]}}</div>
        <div class="account-status">{{status[item["status"]]}}</div>
      </div>
    </div>
  </main>
  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import {getCookie} from "@/utils/cookie";

export default {
  name: 'HistoryPage',
  data() {
    return {
      amount:0,
      showerror:false,
      errorMsg:"",
      loading:false,
      data:[],
      status:{
        0:"完成",
        1:"等待",
        2:"失败",
      },
      type:{
        1:"买入",
        2:"卖出",
        3:"充值",
        4:"提款",
        5:"奖励",
        6:"其他"
      }
    }
  },
  created() {
    this.fetchHistory()
  },
  methods: {
    closeError(){
      this.showerror = false
    },
    openError(msg){
      this.errorMsg = msg
      this.showerror = true
    },
    async fetchHistory(){
      this.loading = true
      axios.get(config.apiHost+'/history', {
        headers: {
          'Content-Type': 'application/json',
          'loginsign':getCookie("signature"),
          'Loginmsg':getCookie("login_msg"),
        }
      }).then(response => {
        // 处理响应数据
        var data = response.data
        if(data.login){
          if(data.ok){
            this.data = data.data;
          }else{
            this.$store.commit('setShowWalletMsg',data.msg);
          }
        }else{
          this.$store.commit('setLoginOut')
          this.$router.push({ name: "MyPage", params: {} });
        }
        this.loading = false
      }).catch(error => {
        this.loading = false
        // 处理错误
        console.error(error);
      });
    }
  }
};
</script>

<style scoped>
.account-list {
  background-color: #333;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 5rem;
}

.account-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #444;
}

.account-item:last-child {
  border-bottom: none;
}

.account-id {
  font-size: 16px;
  margin: 5px;
}

.account-status {
  font-size: 16px;
  color: #bbb;
}

</style>
