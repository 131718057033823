<template>

  <main>
    <div class="product-details">
      <div class="product-title">{{ name }}</div>
      <div class="product-info">
        <div class="info-item">
          年化ARP <span>{{arp}}%</span>
        </div>
        <div class="info-item">
          起投资金 <span>{{start_funds}} USDT</span>
        </div>
        <div class="info-item">
          投资周期 <span>{{cycle}}天</span>
        </div>
      </div>
      <div class="product-description">
        &nbsp;&nbsp;&nbsp;&nbsp;{{describe}}
      </div>
      <div class="interest-details">
        <div class="interest-item">基本利息 <span>{{arp}}%</span></div>
        <div class="interest-item">团队利息 <span>{{team_arp}}%</span></div>
        <div class="interest-item">总共利息 <span>{{arp+team_arp}}%</span></div>
        <div class="interest-item">预期收益 <span>{{(cycle * (buy_funds*(arp/100)/365)).toFixed(2)}} USDT</span></div>
      </div>
      <div class="investment">
        本金 <input type="number" v-model="buy_funds">
      </div>
      <button class="buy-button"   @click="Purchase">购买</button>
    </div>


    <div v-if="showDialog" class="dialogdiv">
      <div id="overlay" class="overlay"></div>
      <div id="dialog" class="dialog">
        <div class="dialog-header">
          <span>购买确认</span>
          <button class="close-button" @click="closeDialog()">&times;</button>
        </div>
        <div class="dialog-content">
          <p>&nbsp;&nbsp;&nbsp;&nbsp;您确认购买{{name}}产品,锁仓{{cycle}}天,基本年化利息{{arp}}%,预计收益{{(cycle * (buy_funds*(arp/100)/365)).toFixed(2)}}USDT吗?</p>
        </div>
        <div class="dialog-actions">
          <button class="cancel-button" @click="closeDialog()">Cancel</button>
          <button class="confirm-button" @click="confirmPurchase()">确认</button>
        </div>
      </div>
    </div>


    <div v-if="showerror" class="dialogdiv">
      <div id="overlay" class="overlay"></div>
      <div id="dialog" class="dialog">
        <div class="dialog-header">
          <span>❗</span>
          <button class="close-button" @click="closeError()">&times;</button>
        </div>
        <div class="dialog-content">
          <p>{{errorMsg}}</p>
        </div>
        <div class="dialog-actions">
          <button class="cancel-button" @click="closeError()">取消</button>
          <button class="confirm-button" @click="toCharge()">充值</button>
        </div>
      </div>
    </div>

  </main>

  <div v-if="loading" class="loading-overlay" >
    <div class="loading-spinner"></div>
  </div>

</template>

<script>
import {mapState} from "vuex";
import axios from "axios";
import config from "@/config";
import {Web3} from "web3";
import {getCookie} from "@/utils/cookie";

export default {
  name: 'OrderDetail',
  data() {
    return {
      showerror:false,
      showDialog: false,
      id: this.$route.params.id,
      name: this.$route.params.name,
      arp: parseFloat(this.$route.params.arp),
      start_funds: this.$route.params.start_funds,
      cycle: this.$route.params.cycle,
      describe: this.$route.params.describe,
      team_arp: 0,
      buy_funds: this.$route.params.start_funds,
      loading:false,
      errorMsg:''
    };
  },
  computed: {
    ...mapState({
      showWalletDialog: state => state.showWalletDialog,
      walletConnected: state => state.walletConnected,
      walletLogin: state => state.walletLogin,
      accountAddress: state => state.accountAddress,
      walletConnectModel: state => state.walletConnectModel,
      team_arp: state => state.team_arp,
      balance: state => state.balance,
      level: state => state.level,
      account_info: state => state.account_info,
    }),
  },
  created() {
  },
  methods: {
    toCharge(){
      this.$router.push({ name: "DepositPage", params: {} });
    },
    openDialog(){
      this.showDialog = true;
    },
    closeError(){
      this.showerror = false
      this.showDialog = false
    },
    openError(msg){
      this.errorMsg = msg
      this.showerror = true
    },
    closeDialog() {
      this.showDialog = false;
    },
    Purchase() {
      if(!this.walletLogin){
        this.$router.push({ name: "MyPage", params: {} });
      }else{
        this.openDialog();
      }
    },
    async tobuytypesign(serverD){
      try {
        const provider = await this.walletConnectModel.getWalletProvider();
        const web3 = new Web3(provider);
        this.$store.commit('setShowWalletMsg',"请在钱包客户端操作!");
        const signature = await web3.eth.signTypedData(this.walletConnectModel.getAddress(), serverD['extra_data']);
        const yewu_data = {
            buysign: signature,
            p_id: this.id,
            amount:this.buy_funds
        }
        this.loading = true
        await axios.post(config.apiHost+'/sign_method', {
          "method":"buy",
          "type":"typesign",
          "type_id":serverD['type_id'],
          "data":yewu_data,
          "extra_data":serverD['extra_data'],
          "signature":signature,
          "sign_msg":serverD['extra_data'],
        },{
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        }).then(response => {
          this.loading = false
          var orderdata = response.data
          if(orderdata.login){
            // 处理响应数据
            if(orderdata.ok){
              var item = orderdata["order"]
              this.$store.commit('setBalance', this.balance-item.amount);
              this.$router.push({ name: "OrderDetail", params: {
                  id: item.id,
                  product_name: item.product_name,
                  arp: item.arp,
                  create_at: item.create_at,
                  end_at: item.end_at,
                  et_arp: item.everday_team_arp,
                  status: item.status,
                  amount:item.amount,
                } });
              this.$store.commit('setShowWalletMsg',"");
            }else{
              this.openError(orderdata.msg)
            }
          }else{
            this.$store.commit('setLoginOut')
            this.$router.push({ name: "MyPage", params: {} });
          }

        }).catch(error => {
          console.error(error);
        });
      } catch (error) {
        this.loading = false
        this.showerror = false
        this.showDialog = false
        console.log(error)
      } finally {
        console.error(2);
      }
      this.loading = false
    },
    async tobuysign(serverD){
      try {
        const provider = await this.walletConnectModel.getWalletProvider();
        const web3 = new Web3(provider);
        const hexMessage = web3.utils.utf8ToHex(serverD["sign_msg"]);
        this.$store.commit('setShowWalletMsg',"请在钱包客户端操作!");
        const signature = await web3.eth.personal.sign(hexMessage, this.walletConnectModel.getAddress(), "");
        const yewu_data = {
          buysign: signature,
          p_id: this.id,
          amount:this.buy_funds
        }
        this.loading = true
        await axios.post(config.apiHost+'/sign_method', {
          "method":"buy",
          "type":"sign",
          "data":yewu_data,
          "extra_data":serverD['extra_data'],
          "signature":signature,
          "sign_msg":serverD['sign_msg'],
        },{
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        }).then(response => {
          var orderdata = response.data
          if(orderdata.login){
            // 处理响应数据
            if(orderdata.ok){
              var item = orderdata["order"]
              this.$store.commit('setBalance', this.balance-item.amount);
              this.$router.push({ name: "OrderDetail", params: {
                  id: item.id,
                  product_name: item.product_name,
                  arp: item.arp,
                  create_at: item.create_at,
                  end_at: item.end_at,
                  et_arp: item.everday_team_arp,
                  status: item.status,
                  amount:item.amount,
                } });
              this.$store.commit('setShowWalletMsg',"");
            }else{
              this.openError(orderdata.msg)
            }
          }else{
            this.$store.commit('setLoginOut')
            this.$router.push({ name: "MyPage", params: {} });
          }
        }).catch(error => {
          console.error(error);
        });
      } catch (error) {
        this.loading = false
        this.showerror = false
        this.showDialog = false
        console.log(error)
      } finally {
        console.error(2);
      }
      this.loading = false
    },
    async handleResponse(response) {
      var serverD = response.data
      if(!serverD.login){
        this.$store.commit('setLoginOut')
        this.$router.push({ name: "MyPage", params: {} });
        return
      }
      if(serverD["type"] == "sign"){
        await this.tobuysign(serverD)
      }
      if(serverD["type"] == "typesign"){
        await this.tobuytypesign(serverD)
      }
    },
    async confirmPurchase(){
      //this.loading=true
      if(this.buy_funds<this.start_funds){
        this.$store.commit('setShowWalletMsg',"投资金额低于起投资金！");
        return
      }
      if(this.balance>this.buy_funds){
        var data = {
          "method":"buy",
          "data":{
            "productid":this.id
          }
        }
        const response = await axios.post(config.apiHost+'/getsign', data, {
          headers: {
            'Content-Type': 'application/json',
            'loginsign':getCookie("signature"),
            'Loginmsg':getCookie("login_msg"),
          }
        })
        await this.handleResponse(response)
      }else{
        this.openError("余额不够,请先充值")
      }

    }
  },
};
</script>

<style scoped>
.dialogdiv{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.product-details {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.product-title {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.product-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-item span {
  margin-top: 5px;
  font-size: 16px;
  color: #bbb;
}

.product-description {
  margin-bottom: 20px;
}

.interest-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.interest-item {
  display: flex;
  justify-content: space-between;
}

.investment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #4d4d4d;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.investment input {
  width: 80px;
  border: none;
  background-color: transparent;
  color: white;
  text-align: right;
  font-size: 16px;
}

.buy-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff00ff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.buy-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}


.dialog {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 2000;

}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dialog-header span {
  font-size: 18px;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.close-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}


.dialog-actions {
  display: flex;
  justify-content: space-between;
}

.cancel-button, .confirm-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

.cancel-button {
  background-color: #555;
  color: white;
}

.confirm-button {
  background-color: #ff00ff;
  color: white;
}
.confirm-button:active {
  background-color: #cc00cc; /* 更深的绿色 */
}


</style>
